/*eslint-disable */
// import Gpath_skill_recoModel from '../../Model/Gpath_skill_reco'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let gpathSkillRecos = {
  /**
   * gpath_skill_recoList
   */
  async gpath_skill_recoList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "gpath_skill_reco_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at gpath_skill_recoList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * gpath_skill_recoView
   */
  async gpath_skill_recoView (context, gpathSkillRecoId) {
    try {      
      let post_data = new FormData();
      post_data.append('gpsr_id', gpathSkillRecoId);
      return await request.curl(context, "gpath_skill_reco_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at gpath_skill_recoView() and Exception:",err.message)
    }    
  },

  /**
   * gpath_skill_recoAdd
   */
  async gpath_skill_recoAdd (context, gpath_skill_recoObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_skill_recoObj) {
        post_data.append(key, gpath_skill_recoObj[key]);
      }

      return await request.curl(context, "gpath_skill_reco_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_skill_recoAdd() and Exception:',err.message)
    }
  },

  /**
   * gpath_skill_recoEdit
   */
  async gpath_skill_recoEdit (context, gpath_skill_recoObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_skill_recoObj) {
        post_data.append(key, gpath_skill_recoObj[key]);
      }

      return await request.curl(context, "gpath_skill_reco_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_skill_recoEdit() and Exception:',err.message)
    }
  },

  /**
   * gpath_skill_recoDelete
   */
  async gpath_skill_recoDelete (context, gpathSkillRecoId) {
    try{
      let post_data = new FormData();
      
      post_data.append('gpsr_id', gpathSkillRecoId);

      return await request.curl(context, "gpath_skill_reco_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpath_skill_recoDelete() and Exception:',err.message)
    }
  }
}

export {
  gpathSkillRecos
}
